<!--
Author: Lily McMullen
Description: This page displays a list of projects with filters (region, cohort, country, category) 
that users can interact with via checkboxes or clickable buttons. The layout adjusts responsively, 
showing filters on the side or top depending on the screen size.
-->

<template>
  <div class="container py-4">
    <header class="text-center mb-4">
      <h1><strong>Explore Projects</strong></h1>
      <p>Search and filter through projects.</p>
      <router-link class="btn btn-blue btn-sm" to="/CARP/map">Back to Map</router-link>
    </header>

    <!-- Search Bar -->
    <div class="row mb-2 px-3" v-show="projects.length > 0">
        <input
          type="text"
          class="form-control"
          v-model="searchQuery"
          placeholder="Search projects by name, country, PI, category..."
        />
    </div>

    <div class="row">
      <div class="col col-12 col-lg-3 mt-3" v-show="projects.length > 0">
        <!-- Filters -->
        <AccordionBlock header-text="Filters" opened="true" accordion-id="filters-accordion">
            <div v-show="sortedRegions.length > 0">
              <h3 class="lead mt-0">Region</h3>
              <div v-for="region in sortedRegions" :key="region" class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="region"
                  :value="region"
                  v-model="selectedRegions"
                />
                <label :for="region" class="form-check-label">{{ region }}</label>
              </div>
            </div>
            <div v-show="sortedCohorts.length > 0">
              <h3 class="lead">Cohort</h3>
              <div v-for="cohort in sortedCohorts" :key="cohort" class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="cohort"
                  :value="cohort"
                  v-model="selectedCohorts"
                />
                <label :for="cohort" class="form-check-label">{{ cohort }}</label>
              </div>
            </div>
            <div class="filter-group" v-show="sortedCountries.length > 0">
              <h3 class="lead">Country</h3>
              <div v-for="country in sortedCountries" :key="country" class="form-check" >
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="country"
                  :value="country"
                  v-model="selectedCountries"
                />
                <label class="form-check-label" :for="country">{{ country }}</label>
              </div>
            </div>
            <div v-show="sortedCategories.length > 0">
              <h3 class="lead">Category</h3>
              <div v-for="category in sortedCategories" :key="category" class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="category"
                  :value="category"
                  v-model="selectedCategories"
                />
                <label class="form-check-label" :for="category">{{ category }}</label>
              </div>
            </div>
        </AccordionBlock>
        <hr class="d-lg-none" />
      </div>
      <div class="col col-12 col-lg-9 px-4 mt-4" v-show="filteredProjects.length == 0">
        <p class="text-center">No projects match selected filters.</p>
      </div>
      <div class="col col-12 col-lg-9 px-4" v-show="projects.length > 0">
        <ProjectList :projects="filteredProjects" />
      </div>
      <div class="col col-12" v-show="projects.length == 0">
        <p class="text-center">No projects found.</p>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import ProjectList from '../components/ProjectList.vue';
  import AccordionBlock from '@/components/AccordionBlock.vue';
  
  export default {
    components: {
      ProjectList,
      AccordionBlock
    },
    props: ['projectData'],
    data() {
      return {
        projects: [], 
        searchQuery: '',
        selectedRegions: [], 
        selectedCohorts: [],
        selectedCountries: [],
        selectedCategories: [],
        filtersExpanded: true
      };
    },
    computed: {
      sortedRegions() {
        // get a list of unique regions from the projects
        return [...new Set(this.projects.map(project => project.region))].sort();
      },
      sortedCohorts() {
        // get a list of unique cohorts
        return [...new Set(this.projects.map(project => project.cohort))].sort();
      },
      sortedCountries() {
        // get a list of unique countries
        var uniqueCountries = []
        for(let project of this.projects){
          for(let country of project.country){
            if(!uniqueCountries.includes(country)){
              uniqueCountries.push(country);
            }
          }
        }
        return uniqueCountries.sort();
      },
      sortedCategories() {
        // get a list of unique categories
        return [...new Set(this.projects.map(project => project.category))].sort();
      },
      filteredProjects() {
        return this.projects.filter(project => {
          // Normalize the search query to lowercase for case-insensitive matching
          const search = this.searchQuery.toLowerCase();
          
          // Check if project title, institution country, or category matches the search query
          const matchesTitle = project.title.toLowerCase().includes(search);
          const matchesCountry = project.institution_country.toLowerCase().includes(search);
          const matchesCategory = project.category.toLowerCase().includes(search);

          // Check if any of the PI names match the search query
          const matchesPI = project.points.some(point => {
            const piName = `${point.pi.title} ${point.pi.first} ${point.pi.last}`.toLowerCase();
            return piName.includes(search);
          });

          // Check all filters: search query + selected filters (region, cohort, etc.)
          const matchesRegion = this.selectedRegions.length === 0 || this.selectedRegions.includes(project.region);
          const matchesCohort = this.selectedCohorts.length === 0 || this.selectedCohorts.includes(project.cohort);
          const matchesCategoryFilter = this.selectedCategories.length === 0 || this.selectedCategories.includes(project.category);

          var matchesCountryFilter = true;
          if(this.selectedCountries.length > 0){
            matchesCountryFilter = false;
            for(let country of project.country){
              if(this.selectedCountries.includes(country)){
                matchesCountryFilter = true;
              }
            }
          }


          // The project must match the search query (in title, country, category, or PI) and pass all selected filters
          return (matchesTitle || matchesCountry || matchesCategory || matchesPI) &&
                matchesRegion && matchesCohort && matchesCountryFilter && matchesCategoryFilter;
        });
      }
    },
    async mounted() {
      try {
        await this.projectData[this.$route.meta.collection].getData().then((response) => {
          response.forEach((project) => {
            this.projects.push(project);
          })
        });
        
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    },
  };
  </script>
  
  <style scoped>
  .linkCursor {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  </style>
  
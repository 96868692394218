<!--
Author: Lily McMullen
Description: This component displays a list of projects with their titles, PIs, cohort year, and institution country.
-->

<template>
  <section class="pb-3 mt-3">
    <ul class="list-unstyled">
      <li v-for="project in shownProjects" v-bind:key="project.id" :id="project.id" :class="highlight == project.id ? 'shadow-lg p-3 mb-3 rounded mt-3' : 'p-3'">
        <ProjectTeaser :project="project" :highlight="highlight == project.id" />
      </li>
    </ul>
    <div class="row px-3">
      <div class="col-3">
        <button class="btn btn-outline-blue btn-sm" @click="previousPage()" v-show="prev">Previous</button>
      </div>
      <div class="col-6 text-center small mt-2">
        <p v-show="($route.name != 'CountryPage')">{{ next || prev ? `Showing ${(page * perPage) + 1} - ${((page + 1) * perPage) < projectList.length ? ((page + 1) * perPage) : projectList.length } of ` : '' }} {{ projectList.length }} {{ projectList.length > 1 || projectList.length == 0 ? 'Projects' : 'Project' }}</p>
      </div>
      <div class="col-3 text-right">
        <button class="btn btn-outline-blue btn-sm" @click="nextPage()" v-show="next">Next</button>
      </div>
    </div>
  </section>
</template>

<script>
import ProjectTeaser from './ProjectTeaser.vue';
export default {
  props: ['projects', 'highlight'],
  components: {
    ProjectTeaser
  },
  data() {
    return {
      projectList: [],
      paginate: false,
      page: 0,
      perPage: 10,
    };
  },
  computed: {
    shownProjects(){
      return this.projectList.slice(this.page * this.perPage, (this.page + 1) * this.perPage);
    },
    next(){
      return ((this.page + 1) * this.perPage < this.projectList.length);
    },
    prev(){
      return this.page > 0;
    }
  },
  methods: {
    // Capitalize first word
    capitalizeFirstWord(str) {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    // Get PI names (this method is for the main project description)
    getPiNames(points) {
      if (!points || points.length === 0) return '';

      const piNames = points.map(point => {
        const pi = point.pi;
        return `${pi.title} ${pi.first} ${pi.last}`;
      });

      const uniquePiNames = [...new Set(piNames)]; // Ensure unique PI names
      return uniquePiNames.length === 1 ? uniquePiNames[0] : uniquePiNames.join(', ');
    },
    // Toggle the expanded state
    toggleDetails(index) {
      if (this.expandedProjectIndex === index) {
        this.expandedProjectIndex = null; // Collapse if already expanded
      } else {
        this.expandedProjectIndex = index; // Expand the selected project
      }
    },
    // Get unique study sites (countries for each point)
    getUniqueStudySites(points) {
      if (!points || points.length === 0) return '';
      const countries = points.map(point => point.country).filter(Boolean); // Ensure country exists
      const uniqueCountries = [...new Set(countries)]; // Ensure unique countries
      return uniqueCountries.length > 0 ? uniqueCountries.join(', ') : 'N/A';
    },
    nextPage(){
      this.page++;
    },
    previousPage() {
      this.page--;
    }
  },
  watch: {
    projects(newVal){
      this.page = 0;
      this.projectList = newVal;
      if(newVal.length > this.perPage){
        this.next = true;
        this.prev = false;
      }
    },
  }
};
</script>
<template>
    <div>
        <h2 :class="highlight ? 'font-weight-bold h4 mt-0' : 'h4 mt-0'">{{ shownProject.title }}</h2>
        <p>
        <strong>{{ getPiNames(shownProject.points) }}</strong><br>
        {{ shownProject.cohort }} - 
        <span v-for="country, i in shownProject.country" v-bind:key="country">
            <router-link :to="'/CARP/' + country">{{ country }}</router-link>{{ shownProject.country.length > 1 && i+1 < shownProject.country.length ? ', ' : '' }}
        </span>
        
        - {{ shownProject.category }}
        </p>
        <AccordionBlock header-text="Summary" :accordion-id="'accordion-' + shownProject.project_id" :opened="false">
            {{ shownProject.summary }}
        </AccordionBlock>
    </div>
</template>

<script>
import AccordionBlock from './AccordionBlock.vue';
export default {
    components: {
        AccordionBlock
    },
    props: ['project', 'highlight'],
    data() {
        return {
            shownProject: {
                title: '',
                points: [],
                cohort: null,
                institution_country: null,
                category: null,
                summary: null
            },
        }
    },
    mounted(){
        this.shownProject = this.project;
    },
    methods: {
        getPiNames(points) {
        if (!points || points.length === 0) return '';

        const piNames = points.map(point => {
            const pi = point.pi;
            return `${pi.title} ${pi.first} ${pi.last}`;
        });

        // Since each project point has its own PI values, check if they are the same using a set
        const uniquePiNames = [...new Set(piNames)];
        
        // If all PI names are the same, return one; otherwise, return all unique names joined by commas
        return uniquePiNames.length === 1 ? uniquePiNames[0] : uniquePiNames.join(', ');
        }
    }
}
</script>
<template>
    <div class="accordion" :id="accordionId">
        <div class="card">
            <div class="card-header" :id="accordionId + '-heading'">
                <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#' + accordionId + '-collapse'" :aria-expanded="showSummary" :aria-controls="accordionId + '-collapse'">
                    {{ headerText }}
                </button>
            </div>
            <div :id="accordionId + '-collapse'" :class="showSummary ? 'collapse show' : 'collapse'" :aria-labelledby="accordionId + '-heading'" :data-parent="'#' + accordionId">
                <div class="card-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionBlock',
    props: ['opened', 'accordionId', 'headerText'],
    data(){
        return {
            showSummary: this.opened ?? false
        }
    },
    methods: {
        showHideSummary() {
            this.showSummary = this.showSummary ? false : true;
        }
    }
}
</script>
<template>
  <div id="app">
    <router-view :projectData="projects"></router-view>
  </div>
</template>

<script>
import { Projects } from "./controller";

export default {
  name: 'App',
  data(){
    return {
      projects: { }
    }
  },
  watch: {
    /**
     * Checks for a route change and queries the collection for that view
     * to pull project data.
     * @param newVal - Meta object from new route.
     */
    '$route.meta'(newVal){
      if(!newVal.collection){
        // no collection in the meta for this route, no need for a new query
        return;
      }
      if(!this.projects[newVal.collection]){
        // the requested collection has yet to be pulled, create new projects
        // object
        this.projects[newVal.collection] = new Projects(newVal.collection)
      }
    }
  }
};
</script>

<style>
@import './styles.css';
</style>

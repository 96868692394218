<template>
  <div class="map-container">
    <div id="map" ref="map" class="leaflet-map"></div>
  </div>
  <div class="hover-title">
    <div>
      <h1 class="mt-0 mb-2">Where We Work</h1>
      <router-link to="/CARP/projects" class="btn btn-blue btn-sm mb-2">View All Projects</router-link>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import files from '@/shapes/importshapes';
const countryShapes = {}
files.keys().forEach((shapeFile) => {
  let trimmed = shapeFile.substr(2);
  let country = trimmed.split('.')[0];
  countryShapes[country] = require(`@/shapes/${trimmed}`)
})

const map = [];

export default {
  props: ['projectData'],
  data() {
    return {
      tileLayerUrl: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
      tileLayerAttribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      customIcon: L.icon({
        iconUrl: require('@/assets/pin-icon.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
      }),
      markers: {},
    };
  },
  async mounted() {
    if(map[0]){
      // removes the map instance (if active), and removes it from the map const variable
      // will reinitialize with the rest of the script
      map[0].remove();
      map.pop();
    }
    map.push(L.map(this.$refs.map).setView([-5, -25], 3));

    L.tileLayer(this.tileLayerUrl, {
      attribution: this.tileLayerAttribution,
      maxZoom: 19
    }).addTo(map[0]);

    try {
      const bounds = L.latLngBounds(); // create bounds for fitting the map

      // Add country shapes to the map
      Object.keys(countryShapes).forEach((country) => {
        const geoJsonLayer = L.geoJSON(countryShapes[country].geometry, {
          style: {
            color: 'blue',
            weight: 2,
            fillColor: 'lightblue',
            fillOpacity: 0.4
          }
        }).addTo(map[0]); // Add to the map here

        // Set up click event for each country shape
        geoJsonLayer.on('click', () => {
          this.$router.push(`/CARP/${country}`)
        });

        bounds.extend(geoJsonLayer.getBounds().getCenter())

      });

      map[0].fitBounds(bounds, { padding: [70, 70]})

    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  }
};
</script>

<style>
.map-container {
  text-align: center;
}

.leaflet-map {
  height: 100vh; 
}

.hover-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.hover-title div {
  background-color: rgb(255,255,255,0.7);
  display: inline-block;
  padding: .5em 2em;
  margin-top: 0;
}
</style>
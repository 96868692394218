<!--
Author: Thomas Weiss (modified from CountryMap.vue)
Description: This component displays a map with markers and shapes indicating project locations
for Haury Native Pathways awardees.
-->

<template>
  <div class="map-container">
    <div id="map" ref="mapContainer"></div>
  </div>
  <div class="loading text-center p-4" v-show="loading">
    <div class="spinner-border text-light" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
  
<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import pinIcon from '../assets/alternate-icon.png'; // custom pin icon for markers

  // import GeoJSON files
  import choctaw from '../assets/haury/choctaw.json';
  import fortpeck from '../assets/haury/fortpeck.json';
  import fortyuma from '../assets/haury/fortyuma.json';
  import navajo from '../assets/haury/navajo.json';
  import tohonooodham from '../assets/haury/tohonooodham.json';
  import crow from '../assets/haury/crow.json';

  // map to associate geometry with file names
  const shapeFiles = {
    'choctaw.json': choctaw.geometry,
    'fortpeck.json': fortpeck.geometry,
    'fortyuma.json': fortyuma.geometry,
    'navajo.json': navajo.geometry,
    'tohonooodham.json': tohonooodham.geometry,
    'crow.json': crow.geometry
  }
  
  export default {
    props: ['data', 'centerId'],
    emits: ['prepModal'],
     data() {
      return {
        /**
         * The Leaflet map.
         */
        map: null,
        /**
         * Array of marker references currently displayed on map.
         */
        markers: [],
        /**
         * Array of shape references currently displayed on map.
         */
        shapes: [],
        /**
         * Boolean to indicate whether map is loading/waiting on data.
         */
        loading: true
      };
    },
    mounted() {
      this.initializeMap(); // Set up the map
    },
    methods: {
      initializeMap() {
        if (this.map) return; // stops the map from initializing again, which causes bugs
  
        this.map = L.map(this.$refs.mapContainer).setView([0, 0], 2);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.map.attributionControl.setPosition('bottomleft'); // moves attribution
      },
      /**
       * Searches the list of marker references to find the marker associated
       * with provided ID.
       * @param id Firestore project ID.
       * @returns Leaflet marker reference associated with ID.
       */
      findMarkerById(id){
        for(let marker of this.markers){
          if(marker.options.projects.includes(id)){
            return marker;
          }
        }
      },
      /**
       * Centers map on a marker associated with provided ID.
       * @param id Firestore project ID.
       */
      centerMap(id){
        let marker = this.findMarkerById(id);
        this.map.flyTo(marker.getLatLng(), 7);
      },
      /**
       * Displays provided data onto Leaflet map.
       * 
       * Expects {shapes: [], points: []}
       * @param data Map data to display.
       */
      addMapItems(data) {
        this.loading = true;
        const self = this;
        // Make sure map is set up before adding markers
        if (!this.map) {
          console.error('Map is not initialized');
          return;
        }
  
        // Set custom icon for the map markers
        const customIcon = L.icon({
          iconUrl: pinIcon,
          iconSize: [38, 38], // icon size
          iconAnchor: [19, 38], // anchor point of the icon
          popupAnchor: [0, -38] // where the popup should open
        });
  
        const bounds = L.latLngBounds(); // create bounds for fitting the map
  
        // Clear existing markers if any
        this.markers.forEach(marker => this.map.removeLayer(marker));
        this.shapes.forEach(shape => this.map.removeLayer(shape));
        this.markers = [];
        this.shapes = [];

        data.points.forEach((point) => {
          // separate div-based icon to also show project count
          const customDivIcon = L.divIcon({
            className: 'divIcon',
            html: `
            <div class="text-center">
              <img src='${pinIcon}' class='pinIcon d-block pb-0 mb-0' />
              <div class='text-center mt-1 p-0'>
                <p class="small bg-red m-0 px-2 py-1 d-inline">${point.projects.length}</p>
              </div>
            </div>`,
            iconAnchor: [19, 38], // anchor point of the icon
            iconSize: [38, 38],
            popupAnchor: [0, -38] // where the popup should open
          });
          const latLng = L.latLng(point.latlon[0], point.latlon[1]);
          // add marker to map with functions and custom icon
          const marker = L.marker(latLng, { icon: point.projects.length > 1 ? customDivIcon : customIcon, projects: point.projects }).on('click', function(){self.$emit('prepModal', point.projects); self.centerMap(point.projects[0])}).addTo(this.map);
          this.markers.push(marker);
          bounds.extend(latLng);
        });

        data.shapes.forEach((shape) => {
          // check if the shape file is in the list, otherwise skip
          // avoids errors in displaying if a new project is added before
          // redeploying app with new geojson file
          if(shapeFiles[shape.file]){
            // separate div-based icon to also show project count
            const customDivIcon = L.divIcon({
              className: 'divIcon',
              html: `
              <div class="text-center">
                <img src='${pinIcon}' class='pinIcon d-block pb-0 mb-0' />
                <div class='text-center mt-1 p-0'>
                  <p class="small bg-red m-0 px-2 py-1 d-inline">${shape.projects.length}</p>
                </div>
              </div>`,
              iconAnchor: [19, 38], // anchor point of the icon
              iconSize: [38, 38],
              popupAnchor: [0, -38] // where the popup should open
            });

            // add shape to map, with click functions, and custom styling
            var mapShape = L.geoJSON(shapeFiles[shape.file], {style: 
              () => {
                return {
                  fillColor: ' #1E5288',
                  weight: 2,
                  opacity: 1,
                  color: '#0C234B',  //Outline color
                  fillOpacity: 0.7
              }
              }}
            ).on('click', function(){self.$emit('prepModal', shape.projects); self.centerMap(shape.projects[0])}).addTo(this.map);
            
            const latLng = mapShape.getBounds().getCenter();
            // add marker to center of shape w/ same functionality as shape
            const marker = L.marker(latLng, { icon: shape.projects.length > 1 ? customDivIcon : customIcon, projects: shape.projects}).on('click', function(){self.$emit('prepModal', shape.projects); self.centerMap(shape.projects[0])}).addTo(this.map);
            this.markers.push(marker);
            this.shapes.push(mapShape);
            bounds.extend(latLng)
          }
        });
  
        if (this.markers.length > 0) {
          this.map.fitBounds(bounds, { padding: [70, 70] });
        } else {
          console.warn('No markers added');
        }
        this.loading = false;
      },
    },
    watch: {
      /**
       * Watches for changes to `data` prop.
       */
      data: {
        handler(newValue){
          this.addMapItems(newValue);
        },
        deep: true
      },
      /**
       * Checks to see if parent wants to center in on a new
       * point.
       * @param newValue New value for variable.
       */
      centerId(newValue) {
        if(newValue != null){
          this.centerMap(newValue);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .map-container {
    width: 100%;
    height: 100%; 
  }
  .loading {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgb(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
  }
  .divIcon{
    text-align: center;
  }
  </style>
  